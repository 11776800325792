import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import Loader from "../Loader";
import StepperForm from "../StepperForm";
import { setLoadHomePage } from "../../store/actions";
import { Button, Grid, Typography } from "@material-ui/core";
import { setCurrentAction } from "../../store/actions";
import "./LandingPage.scss";
import headerImage from "../../../src/assets/images/header.jpeg";
import hchiImage from "../../../src/assets/images/hchi.png";
export default function LandingPage(props) {
  const dispatch = useDispatch();
  const { loading, loadHomePage, generalSettings } = useSelector(
    (params) => params
  );
  const { homePageContent } = generalSettings;
  // const homePageContent = {
  //   backgroundImage:
  //     "http://localhost/exportia-cms/wp-content/uploads/2022/12/header.png",
  //   welcomeBlock: {
  //     heading: "Are you ready to export to Europe?",
  //     heading_color_blue: "Find out now!",
  //     youtuveVideo:
  //       '<iframe width="1280" height="725" src="https://www.youtube.com/embed/Q_0Er8PAW_8" title="Exportia Lab: Setting-up European Sales channels in 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  //     content:
  //       "<p>I am delighted to assist you on this important journey. The European market offers great opportunities for innovative companies. At Exportia we are passionate to turn small businesses into multi-million Euros companies.</p>\n<p>Take the test now and within 20 minutes you will receive an email with a link to download a fully tailored report based on your responses.</p>\n",
  //     buttonName: "Start Quiz",
  //   },
  //   hchiBlock: {
  //     heading: "This report will give you three things",
  //     image:
  //       "http://localhost/exportia-cms/wp-content/uploads/2022/12/hchi.png",
  //     content:
  //       "<ul>\n<li>A benchmark: we will share with you what highly succesful small businesses have implemented to become multi-million Euros businesses.</li>\n<li>Your export score: Where you stand today against our 7 export key success factor</li>\n<li>Your TO DO list to start implementing the first step to generate your first million Euros in sales.</li>\n</ul>\n",
  //   },
  //   footerBlock: {
  //     heading:
  //       "To take the first step towards success in Europe, take this test!",
  //     content:
  //       "<p>To take the first step towards success in Europe, take this test!</p>\n",
  //     buttonName: "Start Quiz",
  //   },
  // };
  const { backgroundImage, welcomeBlock, hchiBlock, footerBlock } =
    homePageContent;
  useEffect(() => {
    dispatch({ type: setCurrentAction, currentAction: "" });
  }, []);

  return (
    <div className="min-h-full main homepage-container">
      {/* Banner Image */}
      {backgroundImage && (
        <section className="landingPage-header-image">
          <img src={headerImage} alt="header-image" />
        </section>
      )}
      {/* Welcome Block */}
      {(welcomeBlock?.heading ||
        welcomeBlock?.content ||
        welcomeBlock?.youtuveVideo) && (
        <section className="landingPage-card-section container">
          <div className="landingPage-card">
            {(welcomeBlock?.heading || welcomeBlock?.heading_color_blue) && (
              <h1 className="landingpage-header">
                {welcomeBlock.heading}
                {welcomeBlock?.heading_color_blue && (
                  <span>{welcomeBlock?.heading_color_blue}</span>
                )}
              </h1>
            )}
            {(welcomeBlock?.content || welcomeBlock?.youtuveVideo) && (
              <div container className="landingPage-card-hchi">
                <div className="content-video w-50">
                  {welcomeBlock?.youtuveVideo && (
                    <div
                      className="youtube-video"
                      dangerouslySetInnerHTML={{
                        __html: welcomeBlock.youtuveVideo,
                      }}
                    />
                  )}
                </div>
                <div className="content content-text w-50">
                  {welcomeBlock?.content && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: welcomeBlock.content,
                      }}
                    />
                  )}

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      dispatch({ type: setLoadHomePage, loadHomePage: false })
                    }
                  >
                    {welcomeBlock.buttonName
                      ? welcomeBlock.buttonName
                      : "Start Quiz"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
      {/* Half content Half image block */}
      {(hchiBlock?.heading || hchiBlock?.image || hchiBlock?.content) && (
        <section container className="landingPage-hchi">
          <div className="container">
            {hchiBlock?.image && (
              <div className="w-50" style={{ textAlign: "center" }}>
                <img
                  src={hchiBlock.image}
                  alt="hchi image"
                  style={{ width: "auto", maxWidth: "100%" }}
                />
              </div>
            )}
            {(hchiBlock?.content || hchiBlock?.heading) && (
              <div className="w-50 content">
                {hchiBlock?.heading && <h2>{hchiBlock.heading}</h2>}
                {hchiBlock?.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: hchiBlock?.content,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </section>
      )}
      {/* Footer Block */}
      {(footerBlock?.heading || footerBlock?.content) && (
        <section container className="landingPage-footer">
          <div className="footer-container">
            {footerBlock?.heading && <h3>{footerBlock.heading}</h3>}
            {footerBlock?.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: footerBlock.content,
                }}
              />
            )}
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                dispatch({ type: setLoadHomePage, loadHomePage: false })
              }
            >
              {footerBlock?.buttonName ? footerBlock.buttonName : "Start Quiz"}
            </Button>
          </div>
        </section>
      )}
    </div>
  );
}
