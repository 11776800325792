import React from "react";
import TermsCondition from "./TermsConditions";
import Privacypolicy from "./PrivacyPolicy";
import { useSelector } from "react-redux";
import siteLogo from "../../../src/assets/images/exportia-logo.png";
import "./Footer.scss";
export default function Footer() {
  const { generalSettings } = useSelector((params) => params);
  const termsServicePage = generalSettings?.termsServicePage;
  const privacyPolicyPage = generalSettings?.privacyPolicyPage;
  return (
    <>
      <footer className="site-footer">
        <div className="footer-container container">
          <div className="footer-logo">
            <img src={siteLogo} alt="site-logo" />
          </div>
          <hr />
          <ul className="footer-list">
            <li>
              © {new Date().getFullYear()} Exportia Pty. Ltd. All rights
              reserved.
            </li>
            <ul className="footer-menu">
              {termsServicePage && (
                <li>
                  <TermsCondition pageData={termsServicePage} />
                </li>
              )}
              {privacyPolicyPage && (
                <li>
                  <Privacypolicy pageData={privacyPolicyPage} />
                </li>
              )}
            </ul>
          </ul>
        </div>
      </footer>
    </>
  );
}
