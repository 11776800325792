import React from "react";
import { Button, Link } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentQuestion,
  setCurrentPage,
  setLoadHomePage,
  setCurrentAction,
} from "../../store/actions";
import "./Navbar.scss";
export default function Nabvar(props) {
  const { generalSettings } = useSelector((params) => params);

  const dispatch = useDispatch();
  function handleClick() {
    localStorage.setItem(
      "exportiaNavData",
      JSON.stringify({ currentPage: 0, currentQuestion: 0 })
    );
    localStorage.setItem("exportiaUserData", JSON.stringify({}));
    dispatch({ type: setCurrentQuestion, currentQuestion: 0 });
    dispatch({ type: setCurrentPage, currentPage: 0 });
    dispatch({ type: setCurrentAction, currentAction: "" });
    dispatch({ type: setLoadHomePage, loadHomePage: true });
  }
  // function handleClickLogo() {
  //   dispatch({ type: setLoadHomePage, loadHomePage: true });
  // }
  return (
    <header className="site-header">
      <div className="navbar-container">
        <div className="container">
          <h1 className="navbar-logo">
            {generalSettings.siteLogo && (
              <Link href="https://exportia.com.au" target="_blank">
                <img
                  src={generalSettings.siteLogo}
                  alt={
                    generalSettings.siteTitle
                      ? generalSettings.siteTitle + " Logo"
                      : "exportia-logo"
                  }
                />
              </Link>
            )}
          </h1>
          {generalSettings.headerButtonText && (
            <Button
              color="primary"
              variant="contained"
              className="ml-auto ml-auot"
              onClick={() => {
                handleClick();
              }}
            >
              {generalSettings.headerButtonText}
            </Button>
          )}
        </div>
      </div>
    </header>
  );
}
