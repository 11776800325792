import React from "react";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import "./EmailUs.scss";

export default function EmailUs() {
  const { generalSettings } = useSelector((params) => params);
  const footerOptions = generalSettings?.footerOptions;
  const openLink = (e) => {
    const url = "https://www.exportia.com.au/contact/";
    window.open(url, "_blank");
  };
  return (
    <>
      {Object.entries(footerOptions).length > 0 && (
        <section className="footer-cta">
          <div className="container">
            <div className="footer-cta-content">
              {footerOptions.heading && (
                <h2 className="heading">{footerOptions.heading}</h2>
              )}
              {footerOptions.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: footerOptions.content }}
                />
              )}
              {footerOptions.button_text && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => openLink(e)}
                  className="footer-cta-btn"
                >
                  {footerOptions.button_text}
                </Button>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
