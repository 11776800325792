import React from "react";
import { Button, Link } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./QuestionFooter.scss";
import {
  setCurrentAction,
  setNavigationClickEvent,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
export default function QuestionFooter(props) {
  const { backStatus, submitButton, handleSubmit } = props;
  const dispatch = useDispatch();
  const { navigationClickEvent } = useSelector((params) => params);
  return (
    <>
      <div className="container question-footer-container">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            dispatch({
              type: setNavigationClickEvent,
              navigationClickEvent: !navigationClickEvent,
            });
            dispatch({ type: setCurrentAction, currentAction: "prev" });
          }}
          disabled={backStatus === true ? false : true}
        >
          Back
        </Button>

        {submitButton ? (
          <Button
            color="primary"
            variant="contained"
            // disabled={continueStatus === true ? false : true}
            onClick={() => handleSubmit()}
          >
            Complete
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch({
                type: setNavigationClickEvent,
                navigationClickEvent: !navigationClickEvent,
              });
              dispatch({ type: setCurrentAction, currentAction: "next" });
            }}
            className="ml-auto"
            // disabled={continueStatus === true ? false : true}
          >
            Continue
          </Button>
        )}
        <div className="d-flex w-100">
          <Link
            className="finish-later-link ml-auto"
            onClick={() =>
              dispatch({
                type: setCurrentAction,
                currentAction: "finish-later",
              })
            }
          >
            Finish Later
          </Link>
        </div>
      </div>
    </>
  );
}
